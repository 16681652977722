<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1487 2.02575C14.9922 0.658082 17.0078 0.658083 17.8513 2.02575L21.3957 7.77344C21.7743 8.38725 22.4411 8.77226 23.1699 8.79784L29.9087 9.03433C31.4971 9.09008 32.4856 10.7556 31.7552 12.1457L28.5193 18.3048C28.1964 18.9194 28.1964 19.6502 28.5193 20.2648L31.7552 26.4239C32.4856 27.814 31.4971 29.4795 29.9087 29.5353L22.6921 29.7885C22.2615 29.8036 21.8675 30.0311 21.6439 30.3938C21.1037 31.2697 19.784 31.1781 19.374 30.2361L18.2223 27.5897C17.6098 26.1824 18.6581 24.6169 20.213 24.6169H20.9315C22.5944 24.6169 23.637 22.8478 22.8119 21.426L17.8807 12.9282C17.0492 11.4954 14.9511 11.4954 14.1197 12.9282L9.18842 21.426C8.36334 22.8478 9.40591 24.6169 11.0689 24.6169H11.7873C13.3422 24.6169 14.3906 26.1824 13.7781 27.5897L12.6263 30.2361C12.2163 31.1782 10.8964 31.2699 10.3561 30.3938C10.1325 30.0311 9.73848 29.8036 9.30784 29.7885L2.09131 29.5353C0.502865 29.4795 -0.485571 27.814 0.244789 26.4239L3.48071 20.2648C3.80363 19.6502 3.80363 18.9194 3.48071 18.3048L0.244789 12.1457C-0.485572 10.7556 0.502863 9.09008 2.09131 9.03433L8.83013 8.79784C9.55893 8.77226 10.2257 8.38724 10.6043 7.77344L14.1487 2.02575Z" :fill="`url(#paint0_linear_1338_20057_${randomId})`" />
        <defs>
            <linearGradient
                :id="`paint0_linear_1338_20057_${randomId}`"
                x1="16"
                y1="1"
                x2="16"
                y2="31"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
        </defs>
    </svg>
</template>
